<template>
  <div class="clients">
    <div class="report-navbar">
    <breadcrumb-view :items="breadcrumb"></breadcrumb-view>

    <principal-bar
      btnText="Create New"
      actionType="showModalCreate"
    ></principal-bar>
    </div>
    <simple-table
      @viewRow="viewDataClient"
      @searchRow="handleSearch"
      :showSearch="true"
      :data="clients"
      :tableColumns="columns"
      :loading="loading"
      :firstColumn="firstColumn"
      class="container"
    ></simple-table>

    <simple-paginator
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :currentPage="paginator.currentPage"
      :pageSize="paginator.pageSize"
      :total="paginator.total"
      :pagerCount="5"
      layout="total, sizes, prev, pager, next, jumper"
      class="container"
    ></simple-paginator>

    <client-modal
      v-if="showModalCreate"
      :showModal="showModalCreate"
      :state="state"
      :brand="brand"
      :id="clientId"
      :title="title">
    </client-modal>

    <view-modal
      v-if="showModalView"
      :showModal="showModalView"
      :data="dataClientItem">
    </view-modal>
  </div>
</template>

<script>

import ApiSSO from '@/lib/ApiSSO';
import SimpleTable from '@/components/Tables/SimpleTable.vue';
import PrincipalBar from '@/components/Bars/PrincipalBar.vue';
import ClientModal from '@/components/Modals/ClientModal.vue';
import ViewModal from '@/components/Modals/ViewModal.vue';
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import SimplePaginator from '@/components/Paginator/SimplePaginator.vue';

export default {
  name: 'ClientsView',
  components: {
    BreadcrumbView,
    ViewModal,
    ClientModal,
    SimpleTable,
    PrincipalBar,
    SimplePaginator,
  },
  props: ['brand', 'name'],
  data() {
    return {
      loading: true,
      showModalCreate: false,
      showModalEdit: false,
      showModalView: false,
      clients: [],
      dataClientItem: {},
      state: 'create',
      title: 'Create New Client',
      clientId: null,
      breadcrumb: [
        {
          string: 'Home',
          active: false,
          link: '/',
        },
        {
          string: 'Clients',
          active: true,
        },
      ],
      columns: [
        {
          prop: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          prop: 'active',
          label: 'Status',
          sortable: true,
          type: 'status',
        },
        {
          prop: 'createdAt',
          label: 'Created Date',
          sortable: false,
          format: 'fromNow',
        },
        {
          prop: 'actions',
          label: 'Actions',
          sortable: false,
          isActions: true,
          typeButtons: 'links',
          buttons: [
            {
              text: 'View',
              action: 'view',
            },
          ],
        },
        {
          prop: 'admin',
          label: 'Admin',
          sortable: false,
          isActions: true,
          typeButtons: 'dropdowns',
          dropdown: [
            {
              text: 'Edit',
              action: 'edit',
            },
            {
              text: 'Change status',
              action: 'changeStatus',
            },
            {
              text: 'Delete',
              action: 'delete',
            },
          ],
        },
      ],
      firstColumn: {
        show: true,
        icon: 'mdi-chart-box-outline',
      },
      paginator: {
        currentPage: 0,
        pageSize: 10,
        total: 0,
        page: 1,
      },
    };
  },
  methods: {
    getAll(search = null) {
      this.loading = true;
      const paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}`;

      let url = `/clients?${paginate}`;

      if (search) {
        url = `/clients?${paginate}&search=${search}`;
      }

      ApiSSO.get(url).then((r) => {
        if (r.success) {
          this.clients = Object.values(r.data);

          if (r.paginator) {
            this.paginator.currentPage = r.paginator.page;
            this.paginator.pageSize = r.paginator.page_size;
            this.paginator.total = r.paginator.count;
          }
        }
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    closeModal() {
      this.showModalView = false;
      this.showModalEdit = false;
      this.showModalCreate = false;
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.state = 'create';
      this.title = 'Create Client';
    },
    viewDataClient(row) {
      this.dataClientItem = row;
      this.showModalView = true;
    },
    editDataClient(data) {
      if (data.type === 'Clients') {
        this.showModalCreate = true;
        this.state = 'update';
        this.title = 'Update Client';
        this.clientId = data.id;
      }
    },
    async hideDataResource(id) {
      try {
        const r = await ApiSSO.put('/clients', {
          id,
          active: false,
        });
        if (r.success) {
          this.getAll();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteClient(id) {
      try {
        const r = await ApiSSO.delete(`/clients/${id}`);
        if (r.success) {
          this.getAll();
        }
      } catch (error) {
        console.error(error);
      }
    },
    currentChange(val) {
      this.paginator.page = val;
      this.getAll();
    },
    sizeChange(val) {
      this.paginator.pageSize = val;
      this.paginator.handlesSizeChange = true;
      this.getAll();
    },
    handleSearch(string) {
      this.getAll(string);
    },
  },
  mounted() {
    this.getAll();
    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('openModalCreate', this.openModalCreate);
    this.$root.$on('reloadDataClients', this.getAll);

    // TABLE EMITS
    this.$root.$on('editRow', this.editDataClient);
    this.$root.$on('hideRow', this.hideDataResource);
    this.$root.$on('deleteRow', this.deleteClient);
  },
};
</script>

<style lang="scss" scoped>

.clients {
  .report-navbar{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto ;
    background: rgba(63, 181, 229, 0.08);
    height: 145px;
    align-items: center;
    padding: 0 80px;

    @media (min-width: 720px){
      padding: 0 90px;
      grid-template-rows: auto;
      grid-template-columns: auto auto ;
    }

    .principal-bar{
      justify-self: end;
    }

  }

  .simple-table{
    margin-top: 20px;
  }
}

</style>
