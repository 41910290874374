<template>
  <div class="roles">
    <div class="report-navbar">
    <breadcrumb-view :items="breadcrumb"></breadcrumb-view>

    <principal-bar
      btnText="Create New"
      actionType="showModalCreate"
    ></principal-bar>
    </div>
    <simple-table
      @viewRow="viewDataRole"
      @viewPermissions="viewPermissionsModal"
      @searchRow="handleSearch"
      :showSearch="true"
      :data="roles"
      :tableColumns="columns"
      :loading="loading"
      :firstColumn="firstColumn"
      class="container"
    ></simple-table>

    <simple-paginator
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :currentPage="paginator.currentPage"
      :pageSize="paginator.pageSize"
      :total="paginator.total"
      :pagerCount="5"
      layout="total, sizes, prev, pager, next, jumper"
      class="container"
    ></simple-paginator>

    <role-modal
      v-if="showModalCreate"
      :showModal="showModalCreate"
      :state="state"
      :id="roleId"
      :applicationId="applicationId"
      :title="title">
    </role-modal>

    <view-modal
      v-if="showModalView"
      :showModal="showModalView"
      :data="dataRoleItem">
    </view-modal>

    <permissions-modal
      v-if="showModalViewPermissions"
      :showModal="showModalViewPermissions"
      :dataRole="dataRoleItem"
      title="Permissions">
    </permissions-modal>
  </div>
</template>

<script>

import ApiSSO from '@/lib/ApiSSO';
import SimpleTable from '@/components/Tables/SimpleTable.vue';
import PrincipalBar from '@/components/Bars/PrincipalBar.vue';
import RoleModal from '@/components/Modals/RoleModal.vue';
import ViewModal from '@/components/Modals/ViewModal.vue';
import PermissionsModal from '@/components/Modals/PermissionsModal.vue';
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import SimplePaginator from '@/components/Paginator/SimplePaginator.vue';

export default {
  name: 'RolesView',
  components: {
    BreadcrumbView,
    ViewModal,
    PermissionsModal,
    RoleModal,
    SimpleTable,
    PrincipalBar,
    SimplePaginator,
  },
  props: ['applicationId'],
  data() {
    return {
      loading: true,
      showModalCreate: false,
      showModalEdit: false,
      showModalView: false,
      showModalViewPermissions: false,
      roles: [],
      dataRoleItem: {},
      state: 'create',
      title: 'Create New Role',
      roleId: null,
      application: {},
      breadcrumb: [
        {
          imgUrl: '',
          active: false,
          type: 'image',
        },
        {
          string: 'Manage Roles',
          active: true,
        },
      ],
      columns: [
        {
          prop: 'name',
          label: 'Name',
          sortable: true,
          align: 'center',
        },
        {
          prop: 'description',
          label: 'Description',
          sortable: false,
          align: 'center',
        },
        {
          prop: 'active',
          label: 'Status',
          sortable: true,
          type: 'status',
        },
        {
          prop: 'createdAt',
          label: 'Created Date',
          sortable: false,
          format: 'fromNow',
          align: 'center',
        },
        {
          prop: 'actions',
          label: 'Actions',
          sortable: false,
          isActions: true,
          typeButtons: 'links',
          align: 'left',
          buttons: [
            {
              text: 'View',
              action: 'view',
              icon: 'mdi-eye',
            },
            {
              text: 'Permissions',
              action: 'permissions-rol',
              icon: 'mdi-shield-key',
            },
          ],
        },
        {
          prop: 'admin',
          label: 'Admin',
          sortable: false,
          isActions: true,
          typeButtons: 'dropdowns',
          align: 'center',
          dropdown: [
            {
              text: 'Edit',
              action: 'edit',
            },
            {
              text: 'Change status',
              action: 'changeStatus',
            },
            {
              text: 'Delete',
              action: 'delete',
            },
          ],
        },
      ],
      firstColumn: {
        align: 'left',
        show: false,
        icon: 'mdi-chart-box-outline',
      },
      paginator: {
        currentPage: 0,
        pageSize: 10,
        total: 0,
        page: 1,
      },
    };
  },
  methods: {
    async getAll(search = null) {
      this.loading = true;
      const paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}`;
      const param = this.applicationId ? `/application/${this.applicationId}` : '';

      let url = `/roles${param}?${paginate}`;

      if (search) {
        url = `/roles${param}?${paginate}&search=${search}`;
      }

      await ApiSSO.get(url).then((r) => {
        if (r.success) {
          this.roles = Object.values(r.data);

          if (r.paginator) {
            this.paginator.currentPage = r.paginator.page;
            this.paginator.pageSize = r.paginator.page_size;
            this.paginator.total = r.paginator.count;
          }
        }
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    closeModal() {
      this.showModalView = false;
      this.showModalEdit = false;
      this.showModalCreate = false;
      this.showModalViewPermissions = false;
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.state = 'create';
      this.title = 'Create Role';
    },
    viewDataRole(row) {
      this.dataRoleItem = row;
      this.showModalView = true;
    },
    viewPermissionsModal(row) {
      this.dataRoleItem = row;
      this.showModalViewPermissions = true;
    },
    editDataRole(data) {
      if (data.type === 'Roles from Application') {
        this.showModalCreate = true;
        this.state = 'update';
        this.title = 'Update Role';
        this.roleId = data.id;
      }
    },
    async hideDataResource(id) {
      try {
        const r = await ApiSSO.put('/roles', {
          id,
          active: false,
        });
        if (r.success) {
          this.getAll();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteRole(id) {
      try {
        const r = await ApiSSO.delete(`/roles/${id}`);
        if (r.success) {
          this.getAll();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getApplication() {
      await ApiSSO.get(`/applications/${this.applicationId}`).then((r) => {
        if (r.success) {
          this.application = r.data;
        }
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
      });
    },
    currentChange(val) {
      this.paginator.page = val;
      this.getAll();
    },
    sizeChange(val) {
      this.paginator.pageSize = val;
      this.paginator.handlesSizeChange = true;
      this.getAll();
    },
    handleSearch(string) {
      this.getAll(string);
    },
  },
  async mounted() {
    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('openModalCreate', this.openModalCreate);
    this.$root.$on('reloadDataRoles', this.getAll);

    // TABLE EMITS
    this.$root.$on('editRow', this.editDataRole);
    this.$root.$on('hideRow', this.hideDataResource);
    this.$root.$on('deleteRow', this.deleteRole);

    if (this.applicationId) {
      await this.getApplication();
      await this.getAll();
      this.App = await this.getApplication();
      // this.breadcrumb[0].imgUrl = this.application.logo;
      // this.breadcrumb.splice(1, {
      //   string: this.application.name,
      //   active: false,
      // });

      this.breadcrumb = [{
        type: 'image',
        imgUrl: this.application.logo,
      }, {
        string: this.application.name,
        active: false,
      }, {
        string: 'Manage Roles',
        active: true,
      }];
    }
  },
};
</script>

<style lang="scss" scoped>

.roles {
  .report-navbar{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto ;
    background: rgba(63, 181, 229, 0.08);
    height: 145px;
    align-items: center;
    padding: 0 80px;

    @media (min-width: 720px){
      padding: 0 90px;
      grid-template-rows: auto;
      grid-template-columns: auto auto ;
    }

    .principal-bar{
      justify-self: end;

    }

  }
}

</style>
