<template class="login">
  <div class="login-screen">
    <el-main
      v-loading="loading"
      class="login-main"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgb(255, 255, 255)"
    >
      <div v-if="!loading" class="header-login">
        <img class="logo" :src="logoApp" alt="logo">
      </div>
      <div class="title">
        <h3>Welcome</h3>
          <p>Log in to <b>{{ nameApp }}</b> to continue.</p>
      </div>
      <!-- <pre>
        Valid Config: {{ validConfig }}
      </pre> -->
      <div class="container-login">
        <template v-if="validConfig">
          <el-form
              label-position="top"
              :model="preLoginForm"
              status-icon
              :rules="rules"
              ref="preLoginForm"
              label-width="120px"
              class="demo-preLoginForm"
              :hide-required-asterisk="true"
            >
            <el-form-item label="Email" prop="email">
              <el-input type="email" v-model="preLoginForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input
                type="password"
                v-model="preLoginForm.password"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <p
              class="invalid-credentials"
              v-if="validPreLoginForm">Verify your username or password.
            </p>

            <el-form-item>
              <el-button
                class="btn-primary"
                :loading="loading"
                type="info"
                @click="preSignIn('preLoginForm')"
                @keypress="preSignIn('preLoginForm')"
                >
                  SIGN IN
              </el-button>
            </el-form-item>

            <div class="mb-20 text-center">
              <span>Or</span>
            </div>

            <div class="google-auth">
              <el-button
                @click="authGoogle"
                type="primary">
                  <i class="mdi mdi-google"></i> Sign up with Google
              </el-button>
            </div>

            <div class="text-center recovery">
              <a :href="'/recovery-password?redirectUrl=' + redirectUrl">Forgot password</a>
            </div>
          </el-form>
        </template>
          <!-- <div class="or">
              <span>Or</span>
          </div>
          <div>
              <button type="button" class="button-auth">
                  <span class="icon-auth"></span>
                  <span>Option 1</span>
              </button>
              <button type="button" class="button-auth">
                  <span class="icon-auth"></span>
                  <span>Option 2</span>
              </button>
              <button type="button" class="button-auth">
                  <span class="icon-auth"></span>
                  <span>Option 3</span>
              </button>
          </div> -->
          <template v-if="!validConfig">
            <h1 class="text-center">Invalid configuration</h1>
          </template>
      </div>
    </el-main>

    <verify-two-fa-modal
      v-if="showVerifyTwoFaModal"
      :showModal="showVerifyTwoFaModal"
      :data="dataLogin"
      title="2FA AUTHENTICATION"
      @valid2Fa="valid2Fa"
    >
    </verify-two-fa-modal>

    <remember-device-modal
      v-if="showRememberDevice"
      :showModal="showRememberDevice"
      :data="dataLogin"
      title="REMEMBER DEVICE?"
      @saveDevice="saveDevice"
    ></remember-device-modal>
  </div>
</template>

<script>
import getBrowserFingerprint from 'get-browser-fingerprint';
import { mapMutations, mapGetters } from 'vuex';
import defaultLogo from '@/assets/sso_icon.png';
import VerifyTwoFaModal from '@/components/Modals/VerifyTwoFaModal.vue';
import ApiSSO from '../../lib/ApiSSO';
import RememberDeviceModal from '../../components/Modals/RememberDeviceModal.vue';

export default {
  name: 'AuthViewExternal',
  props: ['params'],
  components: {
    VerifyTwoFaModal,
    RememberDeviceModal,
  },
  data() {
    return {
      loadingText: 'Validating configuration...',
      validConfig: true,
      showVerifyTwoFaModal: false,
      showRememberDevice: false,
      dataLogin: {},
      defaultLogo,
      logoApp: defaultLogo,
      nameApp: '',
      preLogin: false,
      loginId: '',
      loading: false,
      validPreLoginForm: false,
      token: '',
      validForm: false,
      redirectUrl: null,
      preLoginForm: {
        email: '',
        password: '',
        applicationId: '',
        redirectURL: '',
        fingerPrint: null,
      },
      rules: {
        email: [
          {
            type: 'email',
            required: true,
            message: 'Please enter your user email.',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Please enter your password.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setRefreshToken']),
    preSignIn(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.loginUser();
        }
        this.loading = false;
        return false;
      });
    },
    loginUser() {
      this.loading = true;
      this.loadingText = 'Loading...';

      this.preLoginForm.applicationId = this.params.appId;
      this.preLoginForm.redirectURL = this.params.redirecturl;
      this.preLoginForm.state = this.params.state;

      ApiSSO.post('/authExternal', this.preLoginForm).then((r) => {
        if (r) {
          if (r.success) {
            const {
              token,
              showTwoFa,
              user,
              isKnownDevice,
            } = r.data;
            this.token = token;
            if (showTwoFa && !isKnownDevice) {
              this.dataLogin = {
                user,
                token,
              };
              this.showVerifyTwoFaModal = true;
            } else if (!showTwoFa && !isKnownDevice) {
              this.dataLogin = {
                user,
                refreshToken: token,
              };
              this.showRememberDevice = true;
            } else {
              const st = this.params.state ? `&state=${this.params.state}` : '';
              const redirect = `${this.params.redirecturl}?token=${token}${st}`;
              window.location.href = redirect;
            }
          } else {
            this.$message({
              message: r.message,
              type: 'warning',
            });
          }

          this.loading = false;
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
        this.validPreLoginForm = true;
      });
    },
    async validateAppConfig() {
      try {
        this.loading = true;
        this.loadingText = 'Validating configuration...';
        const r = await ApiSSO.post('/validateAppConfig', this.params);
        if (r.success) {
          this.validConfig = true;
          const { logoApp, nameApp } = r.data;

          this.logoApp = logoApp ?? this.defaultLogo;
          this.nameApp = nameApp;

          document.title = `${nameApp} | Login Auth`;
        } else {
          this.validConfig = false;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    valid2Fa(data) {
      if (data.codeIsValid) {
        const st = this.params.state ? `&state=${this.params.state}` : '';
        const redirect = `${this.params.redirecturl}?token=${data.refreshToken}${st}`;

        if (!data.isKnownDevice) {
          this.dataLogin.refreshToken = data.refreshToken;
          this.showRememberDevice = true;
          return;
        }

        window.location.href = redirect;
      }
    },
    saveDevice(data) {
      if (data?.success) {
        this.showRememberDevice = true;
        const st = this.params.state ? `&state=${this.params.state}` : '';
        const redirect = `${this.params.redirecturl}?token=${this.dataLogin.refreshToken}${st}`;
        window.location.href = redirect;
      }
    },
    async authGoogle() {
      window.location.href = `${process.env.VUE_APP_API_SSO}/googleAuth?applicationId=${this.params.appId}&redirectUrl=${this.params.redirecturl}`;
    },
  },
  async mounted() {
    this.preLoginForm.fingerPrint = await getBrowserFingerprint();
    if (this.isLoggedIn) {
      this.$router.push({ path: '/' });
    }

    const { protocol, host } = (new URL(this.params.redirecturl)) ?? {};

    this.redirectUrl = window.btoa(`${protocol}//${host}`);

    await this.validateAppConfig();
  },
};
</script>
<style scoped lang="scss">

.google-auth{
  display: flex;
  align-items: center;
  justify-content: center;
}
.recovery{
  margin: 25px 0 25px 0;
}

.text-center {
  text-align: center;
}
.login-main {
    width: 400px;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 12px 40px rgb(0 0 0 / 12%);
    border-radius: 5px;
    min-height: 55vh;
    @media only screen and (max-width: 767px) {
        width: 100%;
        min-height: 60vh;
    }
    .header-login{
        margin-top: 30px;
        img{
            display: block;
            -o-object-fit: contain;
            object-fit: contain;
            max-height: 52px;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .title {
        margin-bottom: 30px;
        h3{
            font-size: 24px;
            font-weight: 400;
            letter-spacing: 0;
            text-align: center;
            margin: 30px 0;
        }
        p{
            font-size: 12px;
            margin: auto;
            text-align: center;
        }
    }
    .container-login{
        margin: 0 20px;
        .btn-primary{
            width: 100%;
            height: 52px;
        }
        .or{
            width: 100%;
            display: flex;
            flex-direction: row;
            text-transform: uppercase;
            border: none;
            font-size: 12px;
            font-weight: 500;
            margin: 0 0 20px;
            padding: 24px 0 0;
            &:after, &:before{
                content: "";
                border-bottom: 1px solid #c2c8d0;
                flex: 1 0 auto;
                height: 0.5em;
                margin: 0;
            }
            span{
                text-align: center;
                flex: 0.2 0 auto;
                margin: 0;
            }
        }
        .button-auth{
            display: flex;
            position: relative;
            padding: 0 8px 0 52px;
            background: #fff;
            align-items: center;
            width: 100%;
            font-size: 16px;
            font-family: inherit;
            height: 52px;
            border: 1px solid #c2c8d0;
            border-radius: 3px;
            color: #2d333a;
            cursor: pointer;
            outline: 0;
            margin-bottom: 8px;
            transition: box-shadow .15s ease-in-out,background-color .15s ease-in-out;
            &:hover{
                box-shadow: inset 0 0 0 150px rgb(0 0 0 / 10%);
            }
            .icon-auth{
                position: absolute;
                left: 26px;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 20px;
                height: 20px;
            }
        }
    }
}
</style>
